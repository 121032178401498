import './css/app.scss';
import * as Turbo from '@hotwired/turbo'
import * as Utils from './js/utils';
import resizeText from './js/resizeText';
import './js/header';
import './js/app-height';
import './js/pageimage';
import './js/event-full';
import './js/macy';
import './js/forms';
import './js/articles';
import './js/digital-stage';
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

// Start Stimulus application
const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context));
application.debug = process.env.NODE_ENV === 'development';

// Automatically add all images to the manifest.json
const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

const initialized = new WeakMap();

Utils.foreachElement('.scale-text', (el) => {
    if (initialized.has(el)) {
        return;
    }

    initialized.set(el, true);
    resizeText({ element: el });
});

Turbo.config.drive.progressBarDelay = 300;

// Trigger a custom event when an Instagram embed has loaded
window.__igEmbedLoaded = (item) => {
    document.dispatchEvent(new CustomEvent('igEmbedLoaded', {detail: item}));
}

// Always break out of a missing frame
document.documentElement.addEventListener('turbo:frame-missing', (e) => {
    console.debug(e);
    if (window.console) {
        console.warn('Turbo frame #'+e.target.id+' is missing.');
    }

    e.preventDefault();
    e.detail.visit(e.detail.response);
});
